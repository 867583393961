<template>
  <div class="pay_container">
    <el-row class="pay_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <div class="pay_main">
      <p class="count">支付倒计时：{{ count }}</p>
      <p>就诊人：{{ patName }} ，卡号：{{ query.patCardNo }} {{ query.admissionNum }}</p>
      <p>
        <span class="red">您本次消费的金额为：</span>{{ query.regFee || query.cost }}
        <span class="red">元</span>
      </p>
      <p>
        <span class="red">请在100秒内进行操作！</span>
      </p>
      <img :src="'data:image/png;base64,' + query.qrCode" alt="">
      <p style="font-size:22px">
        <span class="red">使用微信“扫一扫”进行支付，支付完成后需要在手机上点"完成"按钮才能打印小票。</span>
      </p>
      <p style="font-size:22px">
        <span class="red">扫码后请耐心等待系统打印缴费凭证，请勿点击屏幕取消按钮！</span>
      </p>
      <el-button type="primary" @click.native="cancel">取消</el-button>
    </div>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from './footer/index'
export default {
  components: {
    Foo
  },
  data () {
    return {
      name: '',
      patName: '',
      deptName: '',
      count: '',
      timer: '',
      timer1: '',
      query: {},
      imgurl: "",
    }
  },
  created () {
    this.name = this.$route.query.name
    this.patName = this.$route.query.patName
    this.deptName = this.$route.query.deptName
    this.query = this.$route.query
    this.countdown()
    this.getOrderPayStatus()
  },
  beforeDestroy () {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    // 查询订单支付状态
    getOrderPayStatus () {
      let _this = this
      let params = {
        outTradeNo: this.query.outTradeNo
      }
      Promise.all([_this.$api.getOrderPayStatus(params)]).then((data) => {
        const TIME_COUNT = 600
        if (this.count <= TIME_COUNT) {
          if (data[0].data.isPay === 0 || data[0].data.isPay === 7) {
            this.timer = setTimeout(() => {
              this.getOrderPayStatus()
            }, 2000)
          }
          else if (data[0].data.isPay === 1) {
            this.$router.push({
              path: '/succeedOut',
              // query: data[0].data,
              query: {
                orderNum: data[0].data.hospitalOrderNum,
                patName: this.query.patName,
                doctorName: this.query.doctorName,
                deptName: this.query.deptName,
                patCardNo: data[0].data.medicalCardNo,
                cost: data[0].data.cost,
                outTradeNo: data[0].data.outTradeNo,
                remark: data[0].data.remark,
                receiptNum: data[0].data.receiptNum,
                date: data[0].data.createTime,
                name: this.query.name,
                admissionNum: this.query.admissionNum
              }
            })
          }
          else if (data[0].data.isPay === 5) {
            this.$message({
              message: '支付失败',
              type: 'warning'
            })
            this.$router.push('/home')
          }
        }
        else {
          this.$router.push('/home')
        }
      });
    },
    countdown () {
      const TIME_COUNT = 100
      this.count = TIME_COUNT
      this.timer1 = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--
          if (this.count == 0) {
            this.$router.push('/home')
          }
        } else {
          clearInterval(this.timer1)
          this.timer1 = null
        }
      }, 1000)
    },
    cancel () {
      if (this.name == "预约挂号" || this.name == "当天挂号") {
        this.cancelReg()
      }
      this.$router.push('/home')
    },
    // 取消挂号
    cancelReg () {
      let _this = this
      let params = {
        appointmentId: this.query.appointmentId
      }
      Promise.all([_this.$api.unlockReg(params)]).then((data) => {
        this.$message({
          message: '您已取消挂号',
          type: 'warning'
        })
      });

    },
  },
  destroyed () {
    clearInterval(this.timer)
    clearInterval(this.timer1)
  }
}
</script>
<style >
.pay_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.pay_main {
  margin-top: 40%;
  text-align: center;
}

.pay_main .count {
  position: absolute;
  top: 60px;
  right: 40px;
  font-size: 34px;
}

.pay_main p {
  color: #2259a1;
  margin-top: 30px;
  font-size: 50px;
}

.pay_main .red {
  color: red;
  font-size: 48px;
}

.pay_main img {
  width: 400px;
  height: 400px;
}

.pay_main .el-button {
  margin: 40px;
  font-size: 40px;
  width: 220px;
  height: 130px;
}

.pay_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}
</style>
